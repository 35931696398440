const lang= localStorage.getItem("lang");
export const  countReducer = function (state = {lang:lang}, action) {

  //  console.log(action);
    
    switch (action.type) {
      case "CHANGE_lANG":
        return {lang:action.payload}
      case "DECREMENT":
        return state - 1;
      default:
        return state;
    }
  };